import React from 'react';
import { StatusIcon } from '$ui/Integrations/Elements';
import { Heading } from '$ui/Flo/Heading';
import { Button } from '$ui/Flo/Button';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

interface Props {
    resendInvite: () => void;
    inviteSending?: boolean;
}

export const WaitingForInvite = (props: Props) => {
    const { resendInvite, inviteSending } = props;

    return (
        <Container>
            <StatusIcon mode={'syncing'} />
            <Heading level="body1" profile="body">
                We have sent you an email with an invitation to create your
                Agilio ID
            </Heading>
            <Heading level="body2" profile="body">
                If you already have an Agilio ID matching your email, we will
                link this one instead. You will be logged in automatically when
                linking is complete.
            </Heading>
            <ButtonContainer>
                <Button
                    disabled={inviteSending}
                    mode="solid"
                    border="x-small"
                    rounded
                    onClick={() => {
                        resendInvite();
                    }}
                >
                    {inviteSending ? 'Re-sending invite...' : 'Re-send invite'}
                </Button>
            </ButtonContainer>
        </Container>
    );
};

const Container = styled.div`
    ${mix.gap({ size: 4 })};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ButtonContainer = styled.div`
    ${mix.gap({ size: 0.5 })};
    display: flex;
    flex-direction: column;
    align-items: center;
`;
