import { assign, createActor, emit, fromPromise, setup, SnapshotFrom } from 'xstate5';
import { api, refreshCSRFToken } from '$api';
import { AuthDetails, SSOConfig } from '@/auth/modes/types';
import { popup } from '$ui/Login/Popup/Popup';

export const modesMachine = setup({
    types: {

        context: {} as {
            details: AuthDetails | null;
            error: string | null;
        },

        events: {} as
            | ({ type: 'authenticating' } & SSOConfig)
            | { type: 'authenticated' }
            | { type: 'errored' }
            | { type: 'invalidating' }
            | { type: 'logout' }
            | { type: 'refresh' },

        emitted: {} as
            | { type: 'unauthenticated' }
            | { type: 'authenticated', details: AuthDetails }
            | { type: 'errored'; message: string },
    },

    actions: {
        emitUnauthenticatedEvent: emit({ type: 'unauthenticated' }),

        emitAuthenticatedEvent: emit(({ context }) => {
            if (!context.details) {
                throw new Error(
                    'Cannot emit authenticated event when not' +
                    ' authenticated. `context.details` cannot be null when' +
                    ' authenticated',
                );
            }

            return {
                type: 'authenticated',
                details: context.details
            } as const;
        }),

        emitErroredEvent: emit(({ context }) => {
            if (!context.error) {
                throw new Error(
                    'Cannot emit errored event when not in `errored` state',
                );
            }

            return {
                type: 'errored',
                message: context.error,
            } as const;
        }),
    },

    actors: {
        authenticate: fromPromise<AuthDetails,SSOConfig>( async ({ input }) => {
            const { uiBaseUrl } = input;

            await popup({
                baseURL: uiBaseUrl,
                url: '/auth/sso/login',
            })

            const { data } = await api.get<AuthDetails>('/auth/session');
            return data;
        }),

        refresh: fromPromise(async () => {
            await refreshCSRFToken();
            const { data } = await api.get<AuthDetails>('/auth/session');
            return data;
        }),

        logout: fromPromise(async () => {
            await api.delete('/auth/session');
        }),
    }
}).createMachine({
    initial: 'unauthenticated',

    context: {
        details: null,
        error: null,
    },

    states: {
        unauthenticated: {
            entry: [{ type: 'emitUnauthenticatedEvent' }],
            on: {
                authenticating: 'authenticating',
            },
        },

        authenticating: {
            invoke: {
                id: 'authenticate',
                src: 'authenticate',
                input: ({ event }) => {
                    if (event.type === 'authenticating') {
                        return event;
                    }

                    throw new Error('Authenticating requires auth config.');
                },
                onDone: {
                    target: 'authenticated',
                },
                onError: {
                    target: 'errored',
                    actions: assign(({ event }) => {
                        return {
                            error: (event as any).data,
                        };
                    }),
                },
            }
        },

        authenticated: {
            entry: [{ type: 'emitAuthenticatedEvent' }],
            on: {
                invalidating: 'unauthenticated',
            },
        },

        refreshing: {
            invoke: {
                id: 'refreshing',
                src: 'refresh',
                onDone: {
                    target: 'authenticated',
                    actions: assign(({ event }) => {
                        return {
                            details: {
                                token: event.output.token,
                                email: event.output.email,
                                payload: event.output.payload,
                            },
                        };
                    })
                },
                onError: {
                    target: 'errored',
                    actions: [
                        assign({
                            error: 'session_expired',
                        }),
                    ],
                },
            },
        },

        errored: {
            entry: [{ type: 'emitErroredEvent' }],
            on: {
                authenticating: 'authenticating',
            },
        },
    }
});

let actor = createActor(modesMachine);

let started = false;

export function reset() {
    actor = createActor(modesMachine);
    actor.start();
}

export function start() {
    if (!started) {
        actor.start();
        started = true;
    }
}

export function logout() {
    actor.send({ type: 'logout' });
}

export function initiateSSO(uiBaseUrl: string) {
    actor.send({ type: 'authenticating', uiBaseUrl });
}

export function authenticated(snapshot?: SnapshotFrom<typeof modesMachine>) {
    snapshot ??= actor.getSnapshot();

    return snapshot.matches('authenticated');
}

export { actor };
