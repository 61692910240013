import React from 'react';
import { StatusIcon } from '$ui/Integrations/Elements';
import { Heading } from '$ui/Flo/Heading';
import { Button } from '$ui/Flo/Button';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

interface Props {
    status: string;
}

export const PopupStatus = (props: Props) => {
    const { status } = props;
    const [timer, setTimer] = React.useState<number>(5);

    React.useEffect(() => {
        if (timer == 0) {
            closePopup();
        }
        const intervalId = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timer]);

    const closePopup = () => {
        window.close();
    };

    if (status === 'done') {
        return (
            <PopupContainer>
                <StatusIcon mode={'healthy'} />
                <Heading level="body1" profile="body">
                    Agilio SSO link Successful
                </Heading>
                <Heading level="body1" profile="body">
                    You have been logged in
                </Heading>
                <Heading level="body1" profile="body">
                    This window will close in {timer}
                </Heading>
                <Button
                    icon="X"
                    mode="outline"
                    border="x-small"
                    rounded
                    onClick={closePopup}
                >
                    Close now
                </Button>
            </PopupContainer>
        );
    }
    return (
        <PopupContainer>
            <StatusIcon mode={'unhealthy'} />
            <Heading level="body1" profile="body">
                SSO Link Failed
            </Heading>
            <Heading level="body1" profile="body">
                Please try again. If you continue to receive an error contact
                support at dev@leadflo.com
            </Heading>
            <Heading level="body1" profile="body">
                This window will close in {timer}
            </Heading>
            <Button
                icon="X"
                mode="outline"
                border="x-small"
                rounded
                onClick={closePopup}
            >
                Close now
            </Button>
        </PopupContainer>
    );
};

const Container = styled.div`
    ${mix.gap({ size: 4 })};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PopupContainer = styled(Container)`
    ${mix.padding({ padding: [4, 0] })};
`;
