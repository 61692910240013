import React from 'react';
import { useParams } from 'react-router';
import { PopupStatus } from '../Popup/PopupStatus';
import { useUIBaseURL } from '@/config/react';

export const AuthStatus = () => {
    const { status } = useParams<{ status: 'done' | 'failed' }>();
    const uiBaseURL = useUIBaseURL();
    React.useEffect(() => {
        if (uiBaseURL && window.opener) {
            window.opener.postMessage('leadflo:auth:sso:' + status, uiBaseURL);
        }
    }, [uiBaseURL]);

    return <PopupStatus status={status} />;
};
